<template>
  <footer class="bg-white text-gray-800 py-6">
    <div class="container mx-auto">
      <div class="flex flex-col md:flex-row justify-between text-2xl">
        <!-- Section de contact -->
        <div class="lg:w-1/3 mx-auto lg:ml-9 ">
          <img src="../assets/AP.png" alt="Logo" class="lg:h-24 lg:w-52 h-16 w-36" />
        </div>
        <!-- Réseaux sociaux -->
        <div class="w-1/3 mt-4 mx-auto">
          <div class="items-center mx-auto">
            <h2 class="text-2xl font-sans mb-4 text-center whitespace-nowrap">Suivez-nous</h2>
            <ul class="flex justify-center">
              <li>
                <a href="#" class="hover:text-gray-400">
                  <img
                    class="h-9 w-9"
                    src="../assets/tiktok-logo-logo-svgrepo-com.svg"
                    alt=""
                  />
                </a>
              </li>
              <li class="ml-4">
                <a href="#" class="hover:text-gray-400">
                  <svg
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    class="h-9 w-9"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </a>
              </li>
            
              <li class="ml-4">
                <a href="#" class="hover:text-gray-400">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="w-9 h-9"
                    viewBox="0 0 24 24"
                  >
                    <rect
                      width="20"
                      height="20"
                      x="2"
                      y="2"
                      rx="5"
                      ry="5"
                    ></rect>
                    <path
                      d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"
                    ></path>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <!-- Liens rapides -->
        <div class="lg:w-1/3 lg:mt-12  mx-auto mt-4">
          <div class="mx-auto justify-center items-center">
            <button
              class="bg-blue-500 px-4 py-1 text-white poppins rounded-lg ring-red-300 focus:outline-none focus:ring-4 transform transition duration-700 hover:scale-105"
              @click="contactezNous"
            >
              CONTACTEZ-NOUS!
            </button>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    contactezNous() {
      // Ouvrir un client de messagerie avec l'adresse e-mail pré-remplie
      window.location.href = "mailto:hello@afriplume.com";
    },
  },
};
</script>
