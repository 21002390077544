<template>
  <div class="  ">
    <div class="absolute text-white">
      <div class="flex justify-center items-center mb-4">
        <img class="lg:h-24 lg:w-64 h-16 w-36" src="../assets/AP.png" alt="" />
      </div>
      <div class="bg-blue-500">
        <div class="h-4"></div>
        <h1 class="lg:text-4xl text-lg text-center lg:mt-4 animate-pulse">
          Un nouvel horizon littéraire vous attend
        </h1>
        <h3 class="lg:text-xl text-sm text-center lg:mt-4 animate-pulse">
          Avec AfriPlume, découvrez la littérature africaine autrement
        </h3>
        <div class="lg:w-1/3 mx-auto justify-center items-center mt-6 w-2/3">
          <div
            class="cincopa_responsive_iframe"
            style="padding: 56.25% 0 0 0; position: relative"
          >
            <div class="cincopa_responsive_wrapper">
              <iframe
                width="145%"
                height="140%"
                src="https://rtcdn.cincopa.com/iframe.aspx?fid=A4HAcLOLOO68!AsHD7FK0cDtH"
                frameborder="0"
                allowfullscreen
                scrolling="no"
                allow="autoplay *; fullscreen *"
              ></iframe>
            </div>
          </div>
        </div>

        <div class="lg:flex lg:mx-24">
          <div class="lg:w-1/2 lg:mx-24">
            <h1
              class="lg:text-xl text-lg lg:mx-0 mx-2 font-serif text-center lg:text-left lg:mt-6"
            >
              Inscrivez-vous maintenant pour recevoir une notification dès
              l'ouverture !
            </h1>
            <form
              @submit.prevent="login()"
              class="mx-auto flex flex-col items-center"
            >
              <input
                class="block p-2 border text-gray-800 font-bold lg:mt-4 mt-2 border-input-disable rounded-full lg:w-96 w-72 focus:outline-none focus:ring-primary-normal focus:ring focus:ring-opacity-50 shadow-sm focus:border"
                v-model="form.name"
                type="text"
                autocomplete="current-email"
                placeholder="Nom & Prénom"
                required
              />
              <input
                class="block p-2 text-gray-800 border font-bold lg:mt-4 mt-2 border-input-disable rounded-full lg:w-96 w-72 focus:outline-none focus:ring-primary-normal focus:ring focus:ring-opacity-50 shadow-sm focus:border"
                v-model="form.email"
                type="email"
                autocomplete="current-email"
                placeholder="Email"
                required
              />
              <input
                class="block p-2 text-gray-800 border font-bold lg:mt-4 mt-2 border-input-disable rounded-full lg:w-96 w-72 focus:outline-none focus:ring-primary-normal focus:ring focus:ring-opacity-50 shadow-sm focus:border"
                v-model="form.password"
                type="password"
                autocomplete="current-email"
                placeholder="Mot de passe"
                required
              />
              <div
                class="lg:text-2xl text-sm font-serif text-left lg:mt-6 mt-2"
              >
                <button
                  type="submit"
                  class="bg-blue-400 rounded-full px-2 py-2"
                >
                  <span v-if="isLoading">
                    <spiner />
                  </span>
                  <span v-else class="ml-2 flex">
                    <span> S'inscrire</span>
                  </span>
                </button>
              </div>
            </form>
          </div>
          <div class="lg:w-1/2 lg:mx-24">
            <h3 class="lg:text-xl text-lg text-center lg:mt-6 mt-4">
              Lancement officiel dans :
            </h3>
            <div
              class="countdown-timer flex animate-bounce mt-4 lg:mt-0 mx-auto justify-center items-center"
            >
              <div class="timer-item">
                <div class="timer-number lg:text-6xl font-mono text-xl py-2">
                  <p class="lg:mt-9 text-center">{{ days }}</p>
                </div>
                <p class="timer-label text-sm lg:mt-2 text-center">Jours</p>
              </div>

              <h1 class="lg:h-24 h-12 mt-4 lg:mt-12 border-r-2 ml-1"></h1>

              <div class="timer-item ml-1">
                <div class="timer-number lg:text-6xl font-mono text-xl py-2">
                  <p class="lg:mt-9 lg:ml-2 text-center">{{ hours }}</p>
                </div>
                <p class="timer-label rounded-full text-sm lg:mt-2 text-center">
                  Heures
                </p>
              </div>
              <h1 class="lg:h-24 h-12 mt-4 lg:mt-12 border-r-2 ml-1"></h1>
              <div class="timer-item ml-1">
                <div class="timer-number lg:text-6xl text-xl font-mono py-2">
                  <p class="lg:mt-9 lg:ml-2 text-center">{{ minutes }}</p>
                </div>
                <p
                  class="timer-label rounded-full px-1 text-sm lg:mt-2 lg:ml-2 text-center"
                >
                  Minutes
                </p>
              </div>
              <h1 class="lg:h-24 h-12 mt-4 lg:mt-12 border-r-2 ml-1"></h1>
              <div class="timer-item ml-1">
                <div class="timer-number lg:text-6xl font-mono text-xl py-2">
                  <p class="lg:mt-9 text-center">{{ seconds }}</p>
                </div>
                <p
                  class="timer-label rounded-full lg:px-2 text-sm lg:mt-2 text-center"
                >
                  Secondes
                </p>
              </div>
            </div>
          </div>
        </div>
        <h2 class="lg:font-bold text-white lg:text-2xl text-lg text-center">
          Témoignages
        </h2>
        <div class="grid gap-2 lg:grid-cols-3 mx-9 mt-6">
          <div class="max-w-md p-4 border border-white rounded-3xl shadow-lg">
            <div>
              <p class="mt-2 text-white text-left text-sm">
                C’est un site qui permet non seulement au lecteur mais aussi au
                écrivain d’avoir une expérience or du commun Avec des livres
                inspirant , qui nous mette dans le meme esprit que l’auteur Je
                le conseil totalement
              </p>
            </div>
            <div class="flex mt-4">
              <div class="justify-start">
                <img
                  class="object-cover w-20 h-20 border-2 border-green-500 rounded-full"
                  src="../assets/c972ce3d5e2f4ea3d8d0b83ef3423dd1.png"
                />
              </div>

              <a
                href="#"
                class="text-xl font-medium text-white mt-4 text-left ml-6"
                >Joanisse <br />
                <span class="text-sm">Lecteur</span></a
              >
            </div>
          </div>
          <div class="max-w-md p-4 border border-white rounded-3xl shadow-lg">
            <div>
              <p class="mt-2 text-white text-left text-sm">
                AfriPlume est vraiment une bouffée d'air frais pour la
                littérature africaine, et je suis impatient de voir cette
                communauté grandir et prospérer. Merci aux promoteurs et à tous
                ceux qui participent à faire de cette plateforme un véritable
                succès !
              </p>
            </div>
            <div class="flex mt-4">
              <div class="justify-start">
                <img
                  class="object-cover w-20 h-20 border-2 border-green-500 rounded-full"
                  src="../assets/c972ce3d5e2f4ea3d8d0b83ef3423dd1.png"
                />
              </div>

              <a
                href="#"
                class="text-xl font-medium text-white mt-4 text-left ml-6"
                >Dieudonné ABILE <br />
                <span class="text-sm">La Plume Passionné du sage</span></a
              >
            </div>
          </div>
          <div class="max-w-md p-4 border border-white rounded-3xl shadow-lg">
            <div>
              <p class="mt-2 text-white text-sm text-left">
                AfriPlume m’a redonné le goût de la lecture ! Les web romans
                sont si immersifs que je ne peux pas m’arrêter de lire.
                J’apprécie particulièrement la l'originalité des histoires et la
                possibilité de découvrir des écrivains africains talentueux.
              </p>
            </div>
            <div class="flex mt-4">
              <div class="justify-start">
                <img
                  class="object-cover w-20 h-20 border-2 border-green-500 rounded-full"
                  src="../assets/c972ce3d5e2f4ea3d8d0b83ef3423dd1.png"
                />
              </div>

              <a
                href="#"
                class="text-xl font-medium text-white mt-4 text-left ml-6"
                >Jean-Eudes <br />
                <span class="text-sm">Lecteur</span></a
              >
            </div>
          </div>
        </div>
        <div class="h-6"></div>
      </div>
      <footer class="bg-white text-gray-800 py-6">
        <div class="container mx-auto">
          <div class="flex flex-col md:flex-row justify-between text-2xl">
            <!-- Section de contact -->
            <div class="lg:w-1/3 mx-auto lg:ml-9">
              <img
                src="../assets/AP.png"
                alt="Logo"
                class="lg:h-24 lg:w-64 h-16 w-36"
              />
            </div>
            <!-- Réseaux sociaux -->
            <div class="w-1/3 mt-4 mx-auto">
              <div class="items-center mx-auto">
                <h2
                  class="text-2xl font-sans mb-4 text-center whitespace-nowrap"
                >
                  Suivez-nous
                </h2>
                <ul class="flex justify-center">
                  <li>
                    <a href="#" class="hover:text-gray-400">
                      <img
                        class="h-9 w-9"
                        src="../assets/tiktok-logo-logo-svgrepo-com.svg"
                        alt=""
                      />
                    </a>
                  </li>
                  <li class="ml-4">
                    <a href="#" class="hover:text-gray-400">
                      <svg
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        class="h-9 w-9"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </a>
                  </li>

                  <li class="ml-4">
                    <a href="#" class="hover:text-gray-400">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        class="w-9 h-9"
                        viewBox="0 0 24 24"
                      >
                        <rect
                          width="20"
                          height="20"
                          x="2"
                          y="2"
                          rx="5"
                          ry="5"
                        ></rect>
                        <path
                          d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"
                        ></path>
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <!-- Liens rapides -->
            <div class="lg:w-1/3 lg:mt-12 mx-auto mt-4">
              <div class="mx-auto justify-center items-center">
                <button
                  class="bg-blue-500 px-4 py-1 text-white poppins rounded-lg ring-red-300 focus:outline-none focus:ring-4 transform transition duration-700 hover:scale-105"
                  @click="contactezNous"
                >
                  CONTACTEZ-NOUS!
                </button>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>

  <!-- Composant de modal -->
</template>
<script>
//import { VideoPlayer } from "@videojs-player/vue";
//import "video.js/dist/video-js.css";
import axios from "axios";
import TokenService from "../services/storage.service";
import ApiService from "../services/api.service";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
//import spiner from "../components/spiner.vue";
export default {
  components: {
    //spiner,
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        password: "",
        role_id: "3",
        // local: null,
      },
      formData: {
        name: "",
        email: "",
        message: "",
      },
      targetDate: new Date("2024-06-21T21:00:00"), // Remplacez par votre date cible
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      yourWidthVariable: "700%", // Ajoutez la valeur de la largeur souhaitée ici
      yourHeightVariable: "300%",
      isLoading: false,
    };
  },
  mounted() {
    this.updateCountdown();
    setInterval(this.updateCountdown, 1000);
    // Intégrer le script dans la section head
    //const script = document.createElement('script');
    //script.src = 'https://rtcdn.cincopa.com/libasync.js';
    //script.type = 'text/javascript';

    // Attacher le script à la section head du document
    // document.head.appendChild(script);
  },
  methods: {
    updateCountdown() {
      const now = new Date();
      const difference = this.targetDate - now;

      this.days = Math.floor(difference / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((difference % (1000 * 60)) / 1000);
    },
    async login() {
      try {
        this.isLoading = true;
        const response = await axios.post("/api/auth/register", this.form);
        if (response.status === 200) {
          TokenService.saveToken(response.data.access_token);
          ApiService.setHeader();
          this.isLoading = false;
          this.formData.name = this.form.name;
          this.formData.email = this.form.email;
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Votre email a été ajouté avec succès. Vous serez notifié(e) lorsqu'il y aura des mises à jour.",
            timeout: 5000,
          }).show();
          this.submitForm();
          //this.profile();
          //this.role();
        }
      } catch (error) {
        this.isLoading = false;
        // Gestion des erreurs HTTP
        if (error.response && error.response.status === 422) {
          this.showAlert = true;
          this.alert.message = "Adresse e-mail ou mot de passe incorrect.";
        } else {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose s'est mal passé. Merci d'essayer plus tard.";
        }

        setTimeout(() => {
          this.showAlert = false;
        }, 5000);
      }
    },

    submitForm() {
      this.formData.message = "Tenez-moi informé du lancement";
      axios
        .post("/api/sendemail", this.formData)
        .then((response) => {
          (this.formData = {}), (this.isLoading = false);
          console.log(response.data);
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
.custom-background2 {
  background-image: url("~@/assets/front-view-pile-books-with-glasses.jpeg");
  /* Remplacez 'votre-image.jpg' par le nom de votre image */
  background-size: cover;
  /* Ajustez la taille de l'image */
  background-repeat: no-repeat;
  background-position: center center;
  /* Centrez l'image */
  /* Ajoutez d'autres styles Tailwind CSS ou CSS personnalisés au besoin */
}
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.taille {
  height: 67%;
  width: 93%;
}
.slider-item {
  animation: slide 15s linear infinite;
}

@keyframes slide {
  0%,
  100% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-200%);
  }

  75% {
    transform: translateX(-300%);
  }
}
.cincopa_responsive_wrapper {
  height: 60%;
  left: 0;
  position: absolute;
  top: 0;
  width: 60%;
}

/* Pour les écrans dont la largeur est inférieure à 768px (mobile), la hauteur est de 100% */
@media only screen and (max-width: 768px) {
  .cincopa_responsive_wrapper {
    height: 70%;
    left: 0;
    position: absolute;
    top: 0;
    width: 70%;
  }
}

/* Pour les écrans dont la largeur est supérieure à 768px (PC), la hauteur est de 65% */
@media only screen and (min-width: 769px) {
  .cincopa_responsive_wrapper {
    height: 75%;
    left: 0;
    position: absolute;
    top: 0;
    width: 75%;
  }
}
</style>
